@import 'algolia';

@import url('https://fonts.googleapis.com/css2?family=Alata&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400&display=swap');
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #dd00a1;
  --red: #dc3545;
  --orange: #f88600;
  --yellow: #f8b600;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  /* Starburst colors */
  --midnight-blue: #000a2c;
  --midnight-medium: #06184c;
  --midnight-dark: #000925;
  --starlight-blue: #165ff2;
  --starlight-blue-dark: #0b358f;
  --starlight-blue-darker: #072468;
  --aurora-teal: #14d9b5;
  --lunar-blue: #16dcf2;
  --pulsar-purple: #8d29b8;
  --fusion-fuchsia: #b835a1;
  --starburst-teal: #00a7b5;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Montserrat', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  /* Starburst material colours */
  --primary-50: #e1e3e8;
  --primary-100: #b4b9c6;
  --primary-200: #828aa0;
  --primary-300: #4f5b79;
  --primary-400: #2a375d;
  --primary-500: #041440;
  --primary-600: #03123a;
  --primary-700: #030e32;
  --primary-800: #020b2a;
  --primary-900: #000a2c;
  --secondary-50: #e0f4f6;
  --secondary-100: #b3e5e9;
  --secondary-200: #80d3da;
  --secondary-300: #4dc1cb;
  --secondary-400: #26b4c0;
  --secondary-500: #00a7b5;
  --secondary-600: #009fae;
  --secondary-700: #0096a5;
  --secondary-800: #008c9d;
  --secondary-900: #007c8d;
  --top-spacing: 100px;
}

.bg-opacity-0 {
  --bg-opacity: 0;
}
.bg-opacity-25 {
  --bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bg-opacity: 1;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--top-spacing);
}
html,
body {
  height: 100%;
  margin: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-family: 'Barlow', 'Roboto', sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: 0.04rem;
}
.wrap {
  flex: 1 0 auto;

  @media screen and (max-width: 414px) {
    overflow-x: hidden;
  }
}
.content {
  min-height: calc(100vh - 380px);
}
.row {
  width: 100%;
  margin: 0;
}
.container {
  max-width: 1600px;
}
.toc-content {
  position: relative;
}
.show {
  display: block !important;
}
/*----- HEADER -----*/
.main-header {
  margin-bottom: 95px;
}

.header-text {
  display: block;
  padding: 10px 16px;
  color: white;
  font-family: 'Barlow', 'Roboto', sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  letter-spacing: 0.006rem;
}

.navbar {
  padding: 0px 16px;
  box-shadow: none;
}

.navbar-dark {
  display: flex;
  flex-direction: row;
  height: 61px; /* if changed, must update --top-spacing */
  align-items: center;
  background-color: var(--midnight-medium);
}

.navbar-brand {
  margin-right: 0.8rem;
  padding-top: 0;
}

.navbar-collapse {
  width: 100%;
  height: 34px;
  background-color: var(--midnight-dark);

  @media (max-width: 768px) {
    height: auto;
    margin-top: 36px;
    padding: 16px 0;
  }
}

.nav-item {
  font-family: 'Barlow', 'Roboto', sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  letter-spacing: 0.006rem;
}

.navbar-nav > li{
  padding-right: 16px;
}
/* overrides default a:hover colors*/
.header-text:hover,
.nav-item > a:hover {
  color: white;
}

.nav-link {
  padding-top: 5px;
  color: white;
}
// specific to overtake mdb.css
.primary-nav .nav-item .nav-link.active {
  padding-bottom: 4px;
  border-bottom: 4px solid #80d3d4;
}

.navbar-layout {
  display: flex;
  margin-top: 2px;
  padding: 0px 16px;
  list-style: none;
}

.navbar-left-container {
  display: flex;
  align-items: center;

  .nav-item {
    color: white;
  }
}

.navbar-divider {
  height: 24px;
  border-right: 1px solid white;
  margin-top: 1px;
}

.primary-nav {
  position: fixed;
  top: 61px;
  right: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 34px; /* if changed, must update --top-spacing */
  background-color: var(--midnight-dark);
}

.dropdown-docs {
  left: 0;
  top: 100%;
  z-index: 3;
  background: white;
  border-radius: 5px;
  border: none;
  margin: 10px 0;
  overflow: hidden;
  //-webkit-box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.16), 0 2px 10px 0 rgb(0, 0, 0, 0.12);
  //box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.16), 0 2px 10px 0 rgb(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px rgba(0, 10, 44, 0.56),
    0 2px 10px rgba(0, 10, 44, 0.12);
  box-shadow: 0 2px 5px rgba(0, 10, 44, 0.56), 0 2px 10px rgba(0, 10, 44, 0.12);
  font-family: 'Barlow', 'Roboto', sans-serif;
}
.navbar .dropdown-menu a {
  padding: 10px 20px;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.12em;
}
.nav-item .dropdown {
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 0.08em;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dropdown-item {
  padding: 10px 20px;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.08em;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.3rem 1rem;
  }
}

@media (max-width: 768px) {
  .navbar-layout .nav-item {
    padding: 10px 0;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown:hover .dropdown-menu,
  .dropdown-menu .dropdown-item {
    display: none;
  }
}

/*----- FOOTER -----*/
footer {
  flex-shrink: 0;
  margin-top: auto;
}

.page-footer {
  display: flex;
  flex-direction: row;
  background: var(--midnight-blue);
  margin-top: 20px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 13px;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 1rem 0;
  }
}

.page-footer ul {
  padding-inline-start: 0px;
}
li.footer-item {
  list-style-type: none;
  line-height: 23px;

  a:hover {
    color: var(--primary-200);
  }
}
a.footer-icon {
  color: white;
  font-size: 20px;
  margin: 0px 15px;
}
a.footer-icon:hover {
  color: var(--starburst-teal);
}

a.header-link {
  color: var(--fusion-fuchsia);
}
a.header-link:hover {
  color: var(--pulsar-purple);
}

/*----- TYPOGRAPHY -----*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-top: 1rem;
}
.body-1 {
  font-size: 18px;
  letter-spacing: 0.5px;
}
.body-2 {
  font-size: 16px;
  letter-spacing: 0.25px;
}
.subtitle-1 {
  font-size: 18px;
  letter-spacing: 0.15px;
}
.subtitle-2 {
  font-size: 16px;
  letter-spacing: 0.1px;
}

.button {
  font-size: 16px;
  letter-spacing: 1.25px;
  text-transform: none;
}
.btn-primary {
  color: white;
  background-color: var(--starburst-teal) !important;
}
.btn-primary:hover {
  border: none;
  box-shadow: none;
}
.btn-secondary {
  color: white;
  background-color: transparent !important;
  border: 1px solid #bcc2d8;
  box-shadow: none;
}
.btn-secondary:hover {
  border: 1px solid #bcc2d8;
  box-shadow: none;
}
//override mdb to have choice with actual text
.btn {
  border-radius: 4px;
  font-family: 'Barlow', 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  padding: 9px 15px 10px;
  text-transform: none;
}
.caption {
  font-size: 14px;
  letter-spacing: 0.4px;
}
.overline {
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
a {
  color: var(--starburst-teal);
}
a:hover,
a:active {
  color: var(--secondary-900);
}
.spacer-30 {
  margin-top: 30px;
}
.spacer-60 {
  margin-top: 60px;
}
.center {
  text-align: center;
}
.breadcrumbs {
  font-size: 12px;
}
.card-body p {
  font-size: 14px;
  font-weight: light;
  color: black;
}
.tile-title {
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
}

/*----- IMAGES -----*/
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: white;
  border: 0px;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.img-screenshot {
  border: 1px solid var(--midnight-blue);
}
.modal {
}
.modal-content {
  margin: auto;
  padding: 10px;
}

.button-img {
  border: 1px solid black;
  background: none;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px;
  }
}

ol li p img {
  margin-left: -1ch;
}

ol + p img {
  margin-left: 40px;

  @media screen and (max-width: 414px) {
    margin-left: 20px;
    max-width: calc(100% - 20px);
  }
}

/*----- VIDEOS -----*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 1rem;
}
.embed-container iframe,
.embed-container .wistia_embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*----- TABLES -----*/
table {
  display: table;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: grey;
  max-width: 100%;
  min-width: 50%;
  background-color: transparent;
  margin: 30px 0px;
  border: 1px solid var(--primary-50);
}

thead {
  background-color: var(--primary-500);
  color: white;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  font-weight: bold;
  border: 1px solid var(--primary-500);
}

table th {
  background-color: var(--midnight-blue);
  color: white;
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--primary-500);
}

.feature-matrix-label {
  background-color: var(--midnight-medium);
  color: white;
}

table td {
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid var(--primary-50);
}

table tr:nth-child(even) {
  background-color: var(--light);
}

table caption {
  caption-side: top;
  font-weight: bold;
  font-size: larger;
}

/*----- SIDE NAVIGATION STYLES -----*/
.side-navigation {
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  padding: 0px 40px;
}
.side-navigation li {
  line-height: 24px;
  list-style-type: none;
}
.docs-content {
  padding-right: 30px;
  padding-left: 30px;
}
.hidden {
  display: none;
}
.left-nav {
  flex: 1;
  margin-left: 14px;
}
.left-nav li {
  font-size: 0.85rem;
  margin-bottom: 6px;
  list-style-type: none;
}
.left-nav li[onclick] {
  cursor: pointer;
}
.left-nav li a,
.left-nav li a:not([href]):not([tabindex]) {
  color: #212529;
}
.left-nav li a:hover,
.left-nav li a:not([href]):not([tabindex]):hover {
  color: var(--starburst-teal);
}
.left-nav li.active a,
.left-nav .doc-subnav li.active a,
.left-nav .doc-subfolder li.active a {
  color: var(--starburst-teal);
}
.left-nav li.active > a > i {
  transform: rotate(90deg); //flips menu dropdown icon down
}
.left-nav .doc-subnav li {
  line-height: 20px;
  margin-bottom: 6px;
  margin-left: -27px;

  @media screen and (max-width: 414px) {
    margin-left: -7px;
  }
}
.left-nav .doc-subnav li a,
.left-nav .doc-subfolder li a {
  font-size: 0.85rem;
}
.left-nav .doc-subfolder li {
  line-height: 14px;
  margin-bottom: 14px;
  margin-left: -40px;
}

.left-nav .subheader {
  font-weight: bold;
  text-decoration: underline;
}

//same line-height as parent container to vertically center icon
.nav-icon {
  color: var(--pulsar-purple);
  line-height: 24px;
}

/*----- TOC STYLES -----*/
.toc-styles {
  padding: 0px 40px;
  /*background-color: #F0F0F0;
  box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.2);*/
}
.toc-styles ul {
  font-size: 0.8rem;
  padding-inline-start: 1rem;
  border-left: 0.15rem solid var(--starburst-teal);
}
.toc-styles li {
  list-style-type: none;
  margin-top: 0.625rem;
  line-height: 1.6em;
}
.toc-styles li a {
  color: var(--midnight-blue);
}
.toc-styles ul ul {
  border-left: none;
}
.toc-sticky {
  height: 85vh;
  margin-top: 50px;
  margin-bottom: 10px;
  overflow-y: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
}

.landing-page-row-with-nav .toc-sticky {
  height: auto;
  margin-top: 60px;
  margin-bottom: 0px;
  overflow-y: visible;
}

.print-button {
  margin: 15px 0;
}

@media only screen and (max-width: 1024px) {
  .side-navigation {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 768px) {
  .side-navigation {
    position: relative;
    top: 0px;
    padding: 0 20px;
  }
  .toc-sticky {
    height: auto;
    margin-top: 60px;
    margin-bottom: 0px;
    overflow: visible;
    position: relative;
    top: 0px;
  }
}
/*----- HOMEPAGE STYLES -----*/
.homepage-hero {
  background: url(../img/bg/hero-bg.webp);
  background-size: cover;
  background-position: center;
  background-color: var(--midnight-blue);
  color: white;
  display: flex;
  padding: 25px 100px;
  text-align: center;
}
.homepage-personas {
  background-color: #f5f5f5;
  padding: 30px;
  text-align: center;
}
.homepage-gradient {
  background-image: linear-gradient(to top, #001c93 0%, #000033 60%);
  background-size: cover;
}

/*----- SEP STYLES -----*/
.sep-hero {
  background: url(../img/bg/hero-bg.webp);
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  padding: 60px 100px 40px;
  text-align: center;
  position: relative;
}
.container.sep-parent {
  min-height: 116px;
}
.sep-nav {
  position: absolute;
  bottom: 0px;
}
.sep-nav a {
  margin-right: 15px;
}

/*----- LANDING PAGE STYLES -----*/
.landing-page-row-with-nav {
  margin: 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .card {
    height: 100%;
  }
}

.landing-page-resource-item {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1rem;
  max-width: 100%;
  flex: 0 0 100%;

  @media only screen and (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }

  @media only screen and (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
  }

  .card {
    .card-body img {
      padding: 0;
      @media only screen and (min-width: 769px) {
        padding: 40px 20px;
      }
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      @media only screen and (max-width: 576px) {
        width: 25%;
      }
    }
  }
}

.landing-page-image-row {
  @media only screen and (max-width: 1200px) {
    margin: 0;
    flex-direction: column;
  }
  .landing-page-image {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 60px;

    @media only screen and (max-width: 1200px) {
      max-height: 300px;
      display: flex;
      padding-top: 20px;
      padding-bottom: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media only screen and (min-width: 1200px) {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
  }

  .landing-page-text {
    max-width: 100%;
    flex: 0 0 100%;

    @media only screen and (max-width: 1200px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media only screen and (min-width: 1200px) {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .row {
      margin: 0;

      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

/*----- BLOG STYLES -----*/
.right {
  float: right;
}
.bottom {
  align-self: flex-end;
}
.blog-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}
.archives-title-link a,
.tag-align-bottom a,
.post-card .card-title a {
  color: rgba(0, 0, 0, 0.6);
}
.archives-title-link a:hover,
.tag-align-bottom a:hover,
.post-card .card-title a:hover {
  color: var(--pink);
}
.tag-align-bottom {
  margin-bottom: 0;
}
.latest-blog {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.latest-blog img {
  display: cover;
  max-width: 400px;
  max-height: 200px;
  margin-right: 32px;
}
.post-card {
  max-width: 350px;
  margin: 16px 0;
}
.square {
  border-radius: 0;
}
.blog-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.post-card img {
  height: 125px;
  object-fit: contain;
  border-radius: 0;
}
.blog-cards .card-text {
  border-bottom: none;
  padding-bottom: 0;
}
.archive-post-img img {
  height: 100px;
  width: 100px;
  align-self: center;
  object-fit: contain;
}
.archive-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.featured-img {
  float: right;
  max-width: 350px;
  margin: 32px;
}
ul.blog-tags li {
  display: inline-block;
}
ul.blog-tags {
  margin: 24px 0;
}

.pink-gradient {
  background: linear-gradient(40deg, var(--orange), var(--pink)) !important;
}
.orange-gradient {
  background: linear-gradient(40deg, var(--yellow), var(--orange)) !important;
}
.btn-pink {
  color: white;
  background-color: var(--pink) !important;
}

pre {
  background: whitesmoke;
  outline: 1px solid lightgray;
  padding: 10px;
  margin: 5px;
}

.titleAnchor {
  font-size: smaller;
  opacity: 0.3;
}

.titleAnchor:hover {
  opacity: 1;
  color: var(--starburst-teal);
}

dd {
  margin-left: 1.4em;
}

@media (max-width: 769px) {
  .blog-cards {
    grid-template-columns: 1fr 1fr;
  }
  .latest-blog {
    flex-direction: column-reverse;
  }
  .latest-blog img {
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
    max-width: initial;
  }
}

@media (max-width: 414px) {
  .blog-cards {
    grid-template-columns: 1fr;
  }
  .sep-hero {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/*----- ALERT, CODE, AND BLOCKQUOTE STYLES -----*/
.alert,
blockquote {
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  border: 0px solid transparent;
  border-radius: 0rem;
  /* Forces long unborken words/links to break and wrap inside parent div */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
blockquote {
  color: var(--primary-500);
  background-color: var(--primary-50);
  border-left: 4px solid var(--primary-500);
  padding: 1rem 1.25rem 0.5rem;
}
.alert-danger {
  color: #721c24;
  background-color: #ffe9eb;
  border-left: 4px solid var(--danger);
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-left: 4px solid var(--warning);
}
.alert-info {
  color: #397a82;
  background-color: #d1ecf1;
  border-left: 4px solid var(--info);
}
.highlighter-rouge {
  margin-bottom: 16px;
}
pre code {
  word-break: keep-all;
  word-wrap: normal;
}

/* _sass/_post.css */
pre.highlight > button {
  opacity: 0.3;
}

pre.highlight:hover > button {
  opacity: 1;
}

pre.highlight > button:active,
pre.highlight > button:focus {
  opacity: 1;
}

/*----- ANCHOR LINK STYLES
.docs-content h1 { margin-left: -25px;padding-left: 10px; }
.docs-content h2 { margin-left: -23px;padding-left: 10px; }
.docs-content h3 { margin-left: -21px;padding-left: 10px; }
.docs-content h4 { margin-left: -19px;padding-left: 10px; }
.docs-content h5 { margin-left: -17px;padding-left: 10px; }
.docs-content h6 { margin-left: -15px;padding-left: 10px; }
.docs-content h1 .octicon, h2 .octicon, h3 .octicon, h4 .octicon, h5 .octicon, h6 .octicon  {
  visibility: hidden; }
.docs-content h1:hover .octicon, h2:hover .octicon, h3:hover .octicon, h4:hover .octicon, h5:hover .octicon, h6:hover .octicon {
  visibility: visible; }

.octicon {
  fill: currentColor;
  padding: 0;
  margin-left: -16px;
  vertical-align: middle;
}-----*/

strong {
  font-weight: bold;
}
​

// Stardust Backgrounds
.stardustBase {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
}
.stardustPinkBg {
  background-image: url('../img/bg/dust-pink.jpg');
}
​.stardustTealBg {
  background-image: url('../img/bg/dust-teal.jpg');
}
.stardustBlueBg {
  background-image: url('../img/bg/dust-blue.jpg');
}
.whitedustBg {
  background-image: url(../img/bg/dust-white1.jpg),
    url(../img/bg/dust-white2.jpg);
  background-position: top left, right bottom;
  background-repeat: no-repeat, no-repeat;
}
.whitedustTopBg {
  background-image: url(../img/bg/dust-white1.jpg);
  background-position: top left;
  background-repeat: no-repeat;
}
.whitedustBottomBg {
  background-image: url(../img/bg/dust-white2.jpg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
// Galaxy Backgrounds
.galaxyMultiBg {
  background-image: url('../img/bg/galaxy-multi.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}
​.galaxyTealBg {
  background-image: url('../img/bg/galaxy-teal.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto 100%;
}
​.galaxyPurpleBg {
  background-image: url('../img/bg/galaxy-purple.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto 100%;
}

/*----- DOCUMENTATION CONTENT STYLES -----*/
.docs-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  table {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  pre {
    margin: 1.2rem 5px;
  }
  figure {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

.row-with-logo {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  &-text-container {
    flex: 3;
  }

  &-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3rem;
    flex: 1;

    img {
      margin-bottom: auto;
      max-width: 180px;
      max-height: 140px;
    }
  }
}

@media (max-width: 1400px) {
  .row-with-logo {
    flex-direction: column-reverse;

    &-image-container {
      justify-content: flex-start;
      margin-left: 0;
      margin-bottom: 1rem;

      img {
        margin: 0;
      }
    }
  }
}

.logo-tile {
  margin-top: 10px;
  padding-right: 6px;
  padding-left: 6px;
  height: 165px;
}
.logo-card {
  padding: 1em;
  display: flex;
  align-items: center;
  height: 200px;

  img {
    flex: 1;
    object-fit: contain;
  }
}
@media (max-width: 1281px) {
  .logo-card {
    height: 150px;
  }
}

ol,
ul {
  @media screen and (max-width: 414px) {
    padding-inline-start: 20px;
  }
}

/*----- FEEDBACK BLOCK STYLES -----*/
.feedback-container {
  border-left: 2px solid var(--pulsar-purple);
  padding: 1rem;
  margin-top: 2rem;
  position: relative;

  .feedback-thumbs {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0;
      margin-right: 1rem;
      font-size: 0.8rem;
    }

    .thumb-wrapper {
      display: flex;
      margin-top: 0.5rem;
    }

    .feedback-option {
      cursor: pointer;
      font-size: 0.85rem;

      &:hover {
        color: var(--starburst-teal);
        i {
          font-weight: 700;
        }
      }

      &.active {
        color: var(--starburst-teal);

        i {
          font-weight: 700;
        }
      }

      i {
        margin-right: 0.25rem;
        font-weight: 400;
      }
    }
  }

  .feedback-message {
    display: none;
    align-items: center;

    textarea {
      width: 320px;
      text-indent: 0.5rem;
      margin-right: 2rem;
    }
  }

  a.feedback-skip {
    color: var(--starburst-teal);
    text-decoration: underline;
    margin-left: 1rem;
  }

  @media screen and (max-width: 991px) {
    .feedback-thumbs p {
      font-size: 1rem;
    }
  }
}

/* Internal Markdown Pages */
.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem 2rem;

  img {
    max-height: 3.5rem;
    max-width: 3.5rem;
    padding: 0.25rem;
  }

  p {
    margin-bottom: 0;
  }
}

/* Video List */

.video-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 414px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.video-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* _includes/search.html related styles */

#site-search {
  height: 36px;
  right: 16px;
}

#site-search .dropdown-menu {
  border: 0px;
  padding: 0px;
  margin: 0px;
  left: -520px;
  top: -8px;

  form {
    width: 100%;
  }
}

#dropdownSearch {
  top: -1px;
  padding: 6px 8px;
}

#search-container {
  width: 250px;
}

#search-input {
  color: #999999;
  font-size: 14px;
  height: 36px;
  outline: none;
  padding: 0px 10px;
  width: 100%;
}

#search-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

#search-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

#search-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

#search-input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

#results-container {
  position: absolute;
  top: 35px;
  right: 0px;
  z-index: 1030;
  border: 1px solid #eaeaea;
  background: white;
  padding: 0px;
  width: 100%;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow-y: scroll;
  max-height: calc(100vh - 100px);
}

#results-container li {
  font-size: 14px;
  margin-left: 10px;
  padding: 5px;
}

#results-container li a {
  padding: 15px 0;
}

.icon-search {
  color: var(--yellow);
  margin-left: 5px;
}

@media (max-width: 991px) {
  #search-container {
    width: 100%;
  }

  #site-search .dropdown-menu {
    left: -3px;
    width: 100%;
  }

  #results-container {
    max-height: calc(100vh - 500px);
  }
}

@media (max-width: 768px) {
  #site-search {
    top: 61px;
    width: 100%;
    margin: 0 -16px;
    position: absolute;
  }
}

/*----- ALGOLIA SEARCH STYLES -----*/
#dropdownSearchDisplay {
  width: 300px;
}

#algolia-search {
  position: absolute;
  width: 300px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  #searchbox {
    .ais-SearchBox-form {
      height: 36px;
      border-radius: 2px;

      .ais-SearchBox-input {
        font-family: 'Barlow', 'Roboto', sans-serif;
        font-style: normal;
        font-size: 0.875rem;
        letter-spacing: 0.006rem;
        line-height: 1.4;
      }
    }
  }

  .refinement-list {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);

    h6 {
      font-family: 'Barlow', 'Roboto', sans-serif;
      font-style: normal;
      font-size: 0.85rem;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    .ais-RefinementList-list {
      display: flex;

      .ais-RefinementList-checkbox:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }

      .ais-RefinementList-item:not(:last-of-type) {
        margin-right: 1rem;
      }

      .ais-RefinementList-count {
        display: none;
      }
    }

    .ais-RefinementList-item {
      &:has(div > label > input[value='sep']) {
        .ais-RefinementList-labelText {
          text-transform: uppercase;
        }
      }

      &:has(div > label > input[value='galaxy']) {
        .ais-RefinementList-labelText {
          text-transform: capitalize;
        }
      }

      &:has(div > label > input[value='all']) {
        display: none;
      }
    }
  }

  #hits {
    margin-top: 0.5rem;

    a {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #212529;
      padding: 0.5rem 1rem;
    }

    h2 {
      font-family: 'Barlow', 'Roboto', sans-serif;
      font-style: normal;
      font-size: 0.9rem;
      margin: 0 0 0.25rem;
      color: var(--starburst-teal);
    }

    p {
      font-size: 0.8rem;
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }

    .search-breadcrumb {
      color: var(--fusion-fuchsia);
      font-size: 0.65rem;
      font-weight: 500;
      margin-top: 0.25rem;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .ais-Hits-item {
      box-shadow: none;
      padding: 0;
      list-style: none;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }

      .ais-Highlight-highlighted,
      .ais-Snippet-highlighted {
        font-weight: 700;
        padding: 0;
        background-color: unset;
      }
    }
  }

  .refinement-list,
  #stats {
    margin: 0 0.5rem;
  }

  .refinement-list {
    .ais-RefinementList-label {
      margin: 0;
    }
  }

  #stats {
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;

    p {
      margin: 0;
    }

    a {
      color: var(--starburst-teal);
      font-weight: 500;
      width: 100%;
      height: 100%;
      padding: 10px;
      text-decoration: underline;

      &:hover {
        color: var(--secondary-900);
      }
    }
  }

  #product-list {
    .ais-RefinementList-list {
      flex-direction: column;
    }
  }

  #noResults {
    padding: 1.5rem;
    text-align: center;
  }

  .close-search-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    padding: 0 1rem;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0.4rem;

    a {
      padding: 0;
    }

    h6 {
      margin: 0;
    }
  }
}

/*----- SEARCH RESULTS PAGE STYLES -----*/

.search-results-container {
  h1 {
    margin-top: 1rem;
    margin-left: 2rem;
  }
}

#searchResults {
  display: flex;

  .filter-stats-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);

    h6 {
      margin: 0;
      margin-left: 1rem;
    }

    .filter-options {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .version-options-container {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      h6 {
        margin-right: 0.5rem;
      }
    }

    .refinement-list {
      .ais-RefinementList-list {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .ais-RefinementList-item {
        margin-left: 1rem;
      }
      .ais-RefinementList-label {
        margin: 0;
      }
      .ais-RefinementList-count {
        display: none;
      }
      .ais-RefinementList-checkbox:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    #menu {
      .ais-Menu-count {
        display: none;
      }
    }
  }

  .search-results-content {
    padding: 1rem 1rem;
    flex: 1;

    #resultsHits {
      ol {
        list-style: none;
        padding: 0;

        li {
          padding: 0.5rem 1rem;
          border: 0;
          box-shadow: none;

          &:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }

          h2 {
            font-size: 1.5rem;
          }

          p {
            color: #212529;
          }

          a {
            display: block;

            li {
              padding: 0;
            }
          }
        }
      }
    }

    #resultsSearchbox {
      margin: 0 1rem;

      .ais-SearchBox-form {
        height: 3.5rem;
        font-size: 1.25rem;
      }
    }

    #pagination {
      margin-top: 4rem;
      display: flex;
      justify-content: center;
    }
    #resultsStats {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0.5rem 0;
      margin: 0 1rem;

      p {
        margin-bottom: 0;
      }
    }
  }
}
/*----- PRINT -----*/
@media print {
  @page {
    margin: 1.25cm;
  }

  .main-header,
  .side-navigation,
  .col-lg-3,
  .toc-sticky,
  .feedback-container,
  .titleAnchor,
  .breadcrumbs,
  .embed-container,
  .wistia-embed,
  .page-footer,
  .print-button {
    display: none;
  }

  body {
    font-size: 12pt;
  }

  img {
    max-width: 100%;
  }

  .docs-content {
    flex: auto;
    max-width: 100%;
  }

  table,
  img {
    break-inside: avoid;
  }

  a {
    color: black;
  }
}

.multiPagePostTable {
  border: none;
}

.list-group-item.active {
  background-color: var(--starlight-blue-dark);
  border-color: var(--starlight-blue-dark);
}

.material-symbols-rounded {
  vertical-align: bottom;
  font-variation-settings:
  'FILL' 0,
  'wght' 500,
}
